html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: inherit;
}

#__next {
  height: inherit;
  display: flex;
  flex-direction: column;
}

#__next > *:not(footer, header, aside) {
  flex-grow: 1;
}
